import React from "react";

import * as styles from "./container.module.css";

//props are fed in from page.js

//container is the styling around each component that it is defined in
const Container = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

export default Container;
