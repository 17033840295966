import React from "react";
import { graphql } from "gatsby";

import Layout from "../containers/layout";
import Container from "../components/container";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";


export const query = graphql`
  query BlogPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      phoneNumber
      email
      facebook
      twitter
      instagram
      linkedIn
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const gradient = {
    from: "#012c43",
    to: "#012c43"
  };

  return (
    <Layout textWhite={false} site={site} navPlacement={true}>
      <SEO
        title={site.title || "Missing title"}
        description={site.description || "Missing description"}
        keywords={site.keywords || []}
        gradient={gradient} 
        bodyAttr={{
          class: "leading-normal tracking-normal text-white gradient"
        }} 
      />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        <div className="py-6">{postNodes && <BlogPostPreviewList nodes={postNodes} />}</div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
